import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { HomeContext, useHomeContext } from '../../contexts/HomeContext';
import MultWithdrawMethods from '../../components/MultWithdrawMethods';

function WithdrawMethod() {
  const { getWithdrawMethods } = useContext(HomeContext);
  const [withdrawMethods, setWithdrawMehods] = useState();
  useEffect(() => {
    getWithdrawMethods().then(response => {
       setWithdrawMehods(response);
    })
      .catch(error => {
        if (error.response?.data) {
          toast.error("An error occurred retrieving data" + error.response?.data?.message);
          console.log(error.response.data.message);
        }
        else {
          toast.error("An unknown error has occurred");
          console.log(error);
        }


      });
   }, []);
  return (
    <div class="w-full px-6 py-6 mx-auto ">
      <div class="flex flex-wrap -mx-3 h-screen" >
        <div class="flex-none w-full max-w-full px-3">
          <div class="relative   ">

            <MultWithdrawMethods withdrawMethods={withdrawMethods} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WithdrawMethod