import React, { useContext, useEffect, useState } from 'react';
import { HomeContext } from '../../contexts/HomeContext';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import LoadingCircle from '../../components/LoadingCircle';

function ManualTransfer() {
    const { getMethodById } = useContext(HomeContext);
    const [method, setMethod] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams(); // للحصول على ID طريقة الدفع من الرابط

    // استدعاء بيانات طريقة الدفع اليدوية
    useEffect(() => {
        getMethod();
    }, [id]);

    const getMethod = () => {
        getMethodById(id)
            .then((response) => {
                setLoading(false);
                if (response == null) {
                    window.location.href = "/error/404";
                }
                setMethod(response);
             })
            .catch((error) => {
                setLoading(false);
                if (error.response?.data) {
                    toast.error("حدث خطأ أثناء جلب البيانات: " + error.response?.data?.message);
                    console.log(error.response.data.message);
                } else {
                    toast.error("حدث خطأ غير معروف");
                    console.log(error);
                }
            });
    };

    if (loading || method === null) {
        return <LoadingCircle />;
    }

    return (
        <div className="w-full px-6 py-6 mx-auto  mb-20">
            <div className="flex flex-wrap -mx-3 h-screen">
                <div className="flex-none w-full max-w-full px-3">
                    <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                        <div className="p-4">
                            <h2 className="text-center text-xl font-semibold mb-4">تفاصيل التحويل اليدوي</h2>
                            <div className="deposit-method-details">
                                <p><strong>اسم الطريقة:</strong> {method.name}</p>
                                <p><strong>البريد الإلكتروني:</strong> {method.email ? method.email : "غير متوفر"}</p>
                                <p><strong>الحساب:</strong> {method.account ? method.account : "غير متوفر"}</p>
                                <p><strong>الرسالة:</strong> {method.message}</p>
                                <p><strong>ملاحظة:</strong> {method.note ? method.note : "لا توجد ملاحظات"}</p>
                                <img
                                    src={`http://127.0.0.1:8000/images/${method.image}`}
                                    alt={method.name}
                                    className="method-image w-64 h-auto mt-4 object-cover mx-auto"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManualTransfer;
