import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HomeContext, useHomeContext } from '../../contexts/HomeContext';
import LoadingCircle from '../../components/LoadingCircle';
import { AuthContext } from '../../contexts/AuthContext';

function Referrals() {
    const { getReferrals, referrals } = useContext(HomeContext);
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);

    const colors = [
        'bg-blue-200',  // خلفية زرقاء فاتحة
        'bg-green-200', // خلفية خضراء فاتحة
        'bg-yellow-200', // خلفية صفراء فاتحة
        'bg-pink-200',  // خلفية وردية فاتحة
        'bg-purple-200' // خلفية بنفسجية فاتحة
    ];

    useEffect(() => {
        getReferrals()
            .then(() => setLoading(false))
            .catch(error => {
                const message = error.response?.data?.message || "حدث خطأ غير معروف";
                toast.error(`تعذر استرداد البيانات: ${message}`);
                console.error(error);
            });
    }, []);

    const getStatusText = (status) => {
        switch (status) {
            case '0': return "معطّل";
            case '1': return "مفعّل";
            case '2': return "معلّق";
            default: return "غير معروف";
        }
    };

    if (loading) {
        return <LoadingCircle />;
    }
     // إذا كانت الأرباح غير مفعلة
    if (user?.active_earn_referral == 0) {
        return (
            <div className="w-full px-6 py-6 mx-auto  mb-20">
                <div className="relative flex p-6 flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-800 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                    <div className="space-y-4">
                        <p>الأرباح غير مفعّلة. يرجى التواصل مع الدعم لتفعيل الأرباح.</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full px-6 py-6 mx-auto">
            <div className="relative flex p-6 flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-800 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                <div className="space-y-4">
                    {referrals?.length > 0 ? (
                        <>
                            {referrals.map((referral, index) => (
                                <div
                                    key={referral.id}
                                    className={`p-4 rounded-lg shadow-md dark:bg-gray-700 ${colors[index % colors.length]}`}
                                >
                                    <div className="flex justify-between mb-2">
                                        <span className="font-semibold text-gray-700 dark:text-gray-300">معرّف:</span>
                                        <span>{referral.id}</span>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                        <span className="font-semibold text-gray-700 dark:text-gray-300">اسم صديقك:</span>
                                        <span>{referral.name}</span>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                        <span className="font-semibold text-gray-700 dark:text-gray-300">ايميله:</span>
                                        <span>{referral.email}</span>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                        <span className="font-semibold text-gray-700 dark:text-gray-300">الحالة:</span>
                                        <span>{getStatusText(referral.status)}</span>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                        <span className="font-semibold text-gray-700 dark:text-gray-300">أرباحك:</span>
                                        <span>{referral.total_earnings}</span>
                                    </div>
                                </div>
                            ))}
                            <p className="text-gray-500 dark:text-gray-400">ملاحظة: يتم عرض الإيميلات المؤكدة فقط.</p>
                        </>
                    ) : (
                        <p className="text-center text-gray-500 dark:text-gray-400">لا توجد دعوات ناجحة.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Referrals;
