import React, { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    logout();
    // // قم بتنفيذ العمليات اللازمة لتسجيل الخروج
    // Cookies.remove('userLoggedIn');
    // Cookies.remove('token');
    // Cookies.remove('user');

    // // قم بتحويل المستخدم إلى الصفحة الرئيسية
    // navigate('/');
    
  }, []);

  return <div>Logging out...</div>;
};

export default Logout;