import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";

const baseURL =
  window.location.hostname === "localhost"
    ? "http://127.0.0.1:8000/api"
    : "https://app.digitalmoney2024.com/api";

const api = axios.create({
  baseURL,
});

// تأخير الطلبات لمدة ثانيتين
api.interceptors.request.use(
  (config) =>
    new Promise((resolve) => {
      setTimeout(() => {
        const token = Cookies.get("token") ?? "";
        const lang = Cookies.get("lang") ?? "en";
        config.headers.Authorization = `Bearer ${token}`;
        config.headers["X-localization"] = lang;
        resolve(config);
      }, 3000); // 2000ms = 2 seconds
    }),
  (error) => Promise.reject(error)
);

// التعامل مع الأخطاء
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      toast.error("الجلسة منتهية، يرجى إعادة تسجيل الدخول");
      // إعادة التوجيه إلى الصفحة الرئيسية
      window.location.href = "/logout";
    } else {
      const message =
        error.response?.data?.message || "حدث خطأ غير متوقع، حاول لاحقًا";
      toast.error(message);
    }
    return Promise.reject(error);
  }
);

export default api;

