import './App.css';
import Layout from './Layout/Layout';
import { AuthProvider  } from './contexts/AuthContext';
import { BrowserRouter as Router, Route  } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Profile from './pages/profile/Profile';
import Home from './pages/Home/Home';
import Cookies from 'js-cookie';
import { HomeProvider } from './contexts/HomeContext';
import NotLogged from './pages/errors/NotLogged'; 
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse'; 
import Register from './pages/Register/Register';
import AboutUs from './pages/AboutUs/AboutUs';
import ConnectUs from './pages/ConnectUs/ConnectUs'; 
import ErrorPage from './pages/errors/ErrorPage'; 
import DepositMethod from './pages/deposit/DepositMethod';
import Logout from './pages/logout/Logout';
import AddRequestDeposit from './pages/deposit/AddRequestDeposit';
import WithdrawMethod from './pages/withdraw/WithdrawMethod';
import AddRequestWithdraw from './pages/withdraw/AddRequestWithdraw';
import DepositHistory from './pages/deposit/DepositHistory';
import WithdrawHistory from './pages/withdraw/WithdrawHistory'; 
import Packages from './pages/package/Packages';
import MyPackages from './pages/package/MyPackages';
import MyWallet from './pages/MyWallet/MyWallet';
import TransferAllWorld from './pages/new/TransferAllWorld';
import ManualTransfer from './pages/deposit_methods/ManualTransfer';
import OnlineTransfer from './pages/deposit_methods/OnlineTransfer';
import VerifyEmail from './pages/Register/VerifyEmail';
import Referrals from './pages/Referrals/Referrals';
 
function PrivateRoute({ path, element }) { 
  if (Cookies.get('userLoggedIn')) {
    return  element ;
  } else {
    return    <NotLogged />;
  }
}

function App() { 
  
  var loadScript = function(src) {
    var tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    document.getElementsByTagName('body')[0].appendChild(tag);
    }
  // loadScript('./assets/js/plugins/perfect-scrollbar.min.js');
  // loadScript('./assets/js/argon-dashboard-tailwind.js');
  return (
    <div className="App bg-slate-100 text-gray-900 dark:bg-gray-500 dark:text-white ">
      <AuthProvider>
        <HomeProvider>
          <Router>
            <Routes> 
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/privacy-policy" element={ <PrivacyPolicy />}  />
              <Route path="/terms-of-use" element={ <TermsOfUse />}  />
              <Route path="/" element={<Home />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
              <Route exact path="/" element={<Layout />}>
                {/* <Route index element={<Home />} /> */}
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/connect-us" element={<ConnectUs />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/my-wallet" element={<MyWallet />} />
                <Route path="/my-packages" element={<MyPackages />} />
                
                <Route path="/deposit-methods" element={<DepositMethod />} /> 
                <Route path="/deposit-history" element={<DepositHistory />} /> 
                <Route path="/online-transfer/:id" element={<OnlineTransfer />} />
                <Route path="/manual-transfer/:id" element={<ManualTransfer />} />
                <Route path="/deposit-methods/:id" element={<AddRequestDeposit />} />    
                <Route path="/withdraw-methods" element={<WithdrawMethod />} /> 
                <Route path="/withdraw-history" element={<WithdrawHistory />} /> 
                <Route path="/withdraw-methods/:id" element={<AddRequestWithdraw />} />   
                <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
                <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />    
                <Route path="/referrals" element={<PrivateRoute element={<Referrals />} />} />      
              
                <Route path="/logout" element={<Logout />} />
                <Route path="/Transfer-all-world" element={<TransferAllWorld />} />
                
                <Route path="/error/:errorCode" element={<ErrorPage />} />
              </Route>
            </Routes>
          </Router>
          </HomeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;