import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HomeContext } from '../../contexts/HomeContext';
import LoadingCircle from '../../components/LoadingCircle';
import { MdOutlineCancel } from "react-icons/md";
import { LoadingButton } from '@mui/lab';

function MyPackages() {
  const { getMyPackages, myPackages, cancelPackage } = useContext(HomeContext);
  const [loading, setLoading] = useState(true);
  const [loadingCancel, setLoadingCancel] = useState({});

  useEffect(() => {
    getMyPackages()
      .then(response => {
        setLoading(false);
      })
      .catch(error => {
        toast.error("حدث خطأ في جلب البيانات");
      });
  }, []);

  const handleSubmit = async (event, packageId) => {
    event.preventDefault();
    setLoadingCancel(prevLoadingCancel => ({
      ...prevLoadingCancel,
      [packageId]: true
    }));

    const res = await cancelPackage(packageId);
    setLoadingCancel(prevLoadingCancel => ({
      ...prevLoadingCancel,
      [packageId]: false
    }));

    if (res.status) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <div class="w-full px-6 py-6  mb-20 mx-auto">
      <div class="relative  p-5 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
        <h1 className="text-2xl font-semibold text-gray-800 dark:text-white mb-6">اشتراكاتي</h1>

        <div className="overflow-x-auto">
          <table className="min-w-full hidden md:table text-sm text-gray-500 dark:text-gray-400">
            <thead>
              <tr>
                <th className="px-4 py-2">معرّف</th>
                <th className="px-4 py-2">حزمة</th>
                <th className="px-4 py-2">تاريخ الاشتراك</th>
                <th className="px-4 py-2">تاريخ الانتهاء</th>
                <th className="px-4 py-2">السعر</th>
                <th className="px-4 py-2">تم الإنشاء في</th>
              </tr>
            </thead>
            <tbody>
              {myPackages?.map(depo => (
                <tr key={depo.id} className="border-b dark:border-gray-700">
                  <td className="px-4 py-2">{depo.id}</td>
                  <td className="px-4 py-2">{depo.package_id}</td>
                  <td className="px-4 py-2">{depo.subscription_date}</td>
                  <td className="px-4 py-2">{depo.expiration_date}</td>
                  <td className="px-4 py-2">{depo.package.price}</td>
                  <td className="px-4 py-2">{depo.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* عرض على شكل كروت للشاشات الصغيرة */}
          <div className="space-y-4 md:hidden">
            {myPackages?.map(depo => (
              <div key={depo.id} className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md">
                <div className="flex justify-between mb-2">
                  <span className="font-semibold text-gray-700 dark:text-gray-300">معرّف:</span>
                  <span>{depo.id}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold text-gray-700 dark:text-gray-300">حزمة:</span>
                  <span>{depo.package_id}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold text-gray-700 dark:text-gray-300">تاريخ الاشتراك:</span>
                  <span>{depo.subscription_date}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold text-gray-700 dark:text-gray-300">تاريخ الانتهاء:</span>
                  <span>{depo.expiration_date}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold text-gray-700 dark:text-gray-300">السعر:</span>
                  <span>{depo.package.price}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700 dark:text-gray-300">تم الإنشاء في:</span>
                  <span>{depo.created_at}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPackages;
