import React, { useContext, useState } from 'react';

import { AuthContext } from '../../contexts/AuthContext';

const VerifyEmail = () => {
    const { user,resendVerificationEmail ,logout} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const resendVerification = async () => {
        setLoading(true);
        const response = await resendVerificationEmail(); // تعديل هنا
         if (response.success) {
            alert(response.message);
        } else {
            alert(response.message);
        }
    
        setLoading(false);
    };
    

    if (!user) {
        return <div>تحميل...</div>;
    }

    if (user.email_verified_at) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-100">
                <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
                    <div className="mb-4 text-green-500">
                        <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                    </div>
                    <h2 className="text-2xl font-bold mb-4 text-gray-800">Email Already Verified</h2>
                    <p className="text-gray-600 mb-4">لقد تم التحقق من بريدك الإلكتروني بالفعل. يمكنك الاستمرار في استخدام كافة ميزات التطبيق.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8">
                <div className="text-center mb-8">
                    <h2 className="text-2xl font-bold text-gray-800">التحقق من بريدك الإلكتروني</h2>
                    <p className="text-gray-600 mt-2">
                    يرجى التحقق من عنوان بريدك الإلكتروني للوصول إلى كافة الميزات.
                    </p>
                </div>
            
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-yellow-700">
                            لقد تم إرسال رابط التحقق إلى عنوان بريدك الإلكتروني: {user.email}
                            </p>
                           
                        </div>
                    </div>
                </div>
                <p className="text-gray-600 mt-2">
                    بعد التحقق من الايميل يرجى تسجيل الخروج واعادة تسجيل الدخول مجددا
                     </p>
                <div className="text-center">
                    <button
                        onClick={resendVerification}
                        disabled={loading}
                        className={`w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {loading ? 'جاري الارسال...' : 'إعادة إرسال بريد إلكتروني للتحقق'}
                    </button>
                </div>
                <button
                        className=" mt-10 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                        role="menuitem"
                        onClick={logout}
                      >
                        خروج
                      </button>
            </div>
        </div>
    );
};

export default VerifyEmail;