"use client";
import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";
import api from "../axios/api"; 
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null); 
  const [user, setUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
 
  useEffect(() => {
    const storedToken = Cookies.get("token");
    const storedUser = Cookies.get("user");
    const storedUserLoggedIn = Cookies.get("userLoggedIn");
  
    if (storedToken !== null && storedToken !== undefined &&  storedUser !== null && storedUser !== undefined) {
      setToken(storedToken);
      setUser(JSON.parse(storedUser));
      setUserLoggedIn(true);
      Cookies.set("userLoggedIn", storedUserLoggedIn);
    }
  }, []);

  const login = (newToken, newUser) => {
    setToken(newToken);
    setUser(newUser);
    setUserLoggedIn(true);
    Cookies.set("userLoggedIn", true);
    Cookies.set("token", newToken);
    Cookies.set("user", JSON.stringify(newUser));
  };

 
  const loginApi = async (email, password) => {
    const response = await api.post("/login", { email, password });
    const { token, user } = response.data;
    // console.log(token, user)
    login(token, user);
    return response;
  };
  const RegisterApi = async (name, email, password,referralCode) => {
    const response = await api.post("/register", { name, email, password,'referral_code':referralCode });
    // console.log(response);
    const { token, user } = response.data;
    login(token, user);
    return response;
  };
  const logout = () => {
    setToken(null);
    setUser(null);
    setUserLoggedIn(false);
    Cookies.remove("userLoggedIn");
    Cookies.remove("token");
    Cookies.remove("user");
    // CheckSession(userLoggedIn, user);
    // console.log("logout success");
    window.location.href = "/";
  };
 
  const resendVerificationEmail = async () => {
     try {
      const response = await api.post('/email/verification-notification');
      return {
        success: true,
        message: 'Verification link has been sent to your email'
      };
    } catch (error) {
      return {
        success: false,
        message: error.response?.data?.message || 'Failed to send verification email'
      };
    }  
  };
  return (
    <AuthContext.Provider
      value={{
        token,
        userLoggedIn,
        user,
        login,
        logout,
        loginApi,
        RegisterApi,
        resendVerificationEmail

      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
