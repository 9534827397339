import React, { useContext, useEffect, useState } from 'react';
import { HomeContext } from '../../contexts/HomeContext';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import LoadingCircle from '../../components/LoadingCircle';
import WithdrawOnline from './WithdrawOnline';
import WithdrawCrypto from './WithdrawCrypto';
import WithdrawManual from './WithdrawManual'; // إضافة المكون الجديد

function AddRequestWithdraw() {
  const { getMethodWithdrawById, myHome ,getMyHome } = useContext(HomeContext);
  const [method, setMethod] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    getMethod();

    getMyHome()
    .then((response) => {
      setLoading(false);
    })
    .catch((error) => {
      console.log(error.response);

      if (error.response?.status === 401) {
        // إذا انتهت الجلسة، إعادة التوجيه إلى الصفحة الرئيسية
        toast.error("الجلسة منتهية يرجى اعادة تسجيل الدخول");
         
        return;
      }

      if (error.response?.data) {
        toast.error(
          "An error occurred retrieving data" + error.response?.data?.message
        );
        console.log(error.response.data.message);
      } else {
        toast.error("An unknown error has occurred");
        console.log(error);
      }
    });



  }, [id]);

  const getMethod = () => {
    getMethodWithdrawById(id)
      .then((response) => {
        setLoading(false);
        if (response == null) {
          window.location.href = "/error/404";
        }
        setMethod(response);
      })
      .catch((error) => {
        toast.error("An error occurred retrieving data");
      });
  };

 
  if (loading || method === null) {
    return <LoadingCircle />;
  }

  return (
    <div className="w-full px-6 py-6 mx-auto  mb-20">
      <div className="flex flex-wrap -mx-3 h-screen">
        <div className="flex-none w-full max-w-full px-3">
          <div className="relative flex p-6 flex-col min-w-0 mb-6 bg-white border-0 shadow-xl rounded-2xl">
            <h1 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">إضافة طلب سحب</h1>

            {/* عرض رسالة حالة السحب */}
            <div
              className={`p-4 mb-6 rounded-lg ${myHome.withdraw_enabled  == '1' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}
            >
              {myHome.withdraw_enabled == '1' ? myHome.withdraw_enabled_msg : myHome.withdraw_disabled_msg}
            </div>

            {/* عرض أقسام السحب فقط إذا كانت الطلبات مفعلة */}
            {myHome.withdraw_enabled  == '1' ? (
              method.type === '1' ? (
                <WithdrawOnline method={method} />
              ) : method.type === '3' ? (
                <WithdrawCrypto method={method} />
              ) : method.type === '2' ? (
                <WithdrawManual method={method} />
              ) : (
                <p>نوع السحب غير مدعوم</p>
              )
            ) : null}
          </div>

        </div>
      </div>
    </div>
  );
}

export default AddRequestWithdraw;
