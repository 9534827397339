import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HomeContext } from '../../contexts/HomeContext';
import LoadingCircle from '../../components/LoadingCircle';
import { NavLink } from 'react-router-dom';

function WithdrawHistory() {
  const { getWithdrawHistory, withdrawHistory } = useContext(HomeContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getWithdrawHistory()
      .then(response => {
        setLoading(false);
       })
      .catch(error => {
        if (error.response?.data) {
          toast.error("An error occurred retrieving data: " + error.response.data.message);
          console.log(error.response.data.message);
        } else {
          toast.error("An unknown error has occurred");
          console.log(error);
        }
      });
  }, []);

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <div className="w-full px-6 py-6 mx-auto  mb-20">
      <div className="flex flex-wrap -mx-3 h-screen">
        <div className="flex-none w-full max-w-full px-3">
          <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
            <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
              <h1 className="dark:text-white">طلبات السحب</h1>
            </div>
            <div className="flex-auto px-0 pt-0 pb-2">
              <div className="p-0 overflow-x-auto min-h-[500px]">
                <div className="m-5">
                  <NavLink
                    to="/withdraw-methods"
                    className="text-white bg-yellow-400 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-yellow-400 dark:hover:bg-yellow-600 focus:outline-none dark:focus:ring-yellow-600"
                  >
                    اضافة طلب جديد
                  </NavLink>
                </div>

                {/* Card view for mobile screens */}
                <div className=" space-y-4 mb-20 p-5">
                  {withdrawHistory && withdrawHistory.length > 0 ? (
                    withdrawHistory.map((depo) => (
                      <div key={depo.id} className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md">
                        <p className="text-gray-800 dark:text-white font-semibold">معرّف: {depo.id}</p>
                        <p className="text-gray-500 dark:text-gray-400">المبلغ: {depo.amount}</p>
                        <p className="text-gray-500 dark:text-gray-400">الحالة: {depo.status}</p>
                        <p className="text-gray-500 dark:text-gray-400">المستلم: {depo.transaction_to}</p>
                        <p className="text-gray-500 dark:text-gray-400">رقم العملية: {depo.transaction_number}</p>
                        <p className="text-gray-500 dark:text-gray-400">طريقة السحب: {depo.withdraw_method_id}</p>
                        <p className="text-gray-500 dark:text-gray-400">ملاحظة: {depo.note}</p>
                        <p className="text-gray-500 dark:text-gray-400">تم الإنشاء في: {depo.created_at}</p>
                        <p className="text-gray-500 dark:text-gray-400">تم التحديث في: {depo.updated_at}</p>
                      </div>
                    ))
                  ) : (
                    <div className="text-center text-gray-500 dark:text-gray-400">
                      لا توجد نتائج لعرضها.
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithdrawHistory;
