import React from "react";
import { NavLink } from "react-router-dom";
import { AiOutlinePlusCircle, AiOutlineMinusCircle, AiOutlineFundProjectionScreen } from "react-icons/ai";


function DepositWithdrawButtons({closeTabByClick}) {
 

  return (
   
        <div className="flex justify-evenly mb-6">
          {/* زر اضافة ايداع جديد */}
          <NavLink
          onClick={closeTabByClick}
            to={"/deposit-methods"}
            className="z-20 px-4 py-2.5 m-3 font-bold text-sm leading-normal text-center transition-all bg-white border border-green-500 rounded-lg shadow-md cursor-pointer text-green-500 hover:bg-green-500 hover:text-white hover:shadow-xs active:opacity-85"
          >
            <AiOutlinePlusCircle className="inline-block mr-1 w-5 h-5" /> إيداع
          </NavLink>

          {/* زر طلب سحب رصيد */}
          <NavLink
          onClick={closeTabByClick}
            to={"/withdraw-methods"}
            className="z-20 px-4 py-2.5  m-3  font-bold text-sm leading-normal text-center transition-all bg-white border border-red-500 rounded-lg shadow-md cursor-pointer text-red-500 hover:bg-red-500 hover:text-white hover:shadow-xs active:opacity-85"
          >
            <AiOutlineMinusCircle className="inline-block mr-1 w-5 h-5" /> سحب
          </NavLink>

          {/* زر استثمار */}
          <NavLink
          onClick={closeTabByClick}
            to={"/packages"}
            className="z-20 px-4 py-2.5  m-3  font-bold text-sm leading-normal text-center transition-all bg-white border border-blue-500 rounded-lg shadow-md cursor-pointer text-blue-500 hover:bg-blue-500 hover:text-white hover:shadow-xs active:opacity-85"
          >
            <AiOutlineFundProjectionScreen className="inline-block mr-1 w-5 h-5" /> استثمار
          </NavLink>
        </div> 
  );
}

export default DepositWithdrawButtons;
