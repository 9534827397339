import React, { useContext, useState } from 'react';
import toast from "react-hot-toast";
import { HomeContext } from '../../contexts/HomeContext';
import { LoadingButton } from "@mui/lab";
import { BsSendFill } from 'react-icons/bs';

const WithdrawCrypto = ({ method }) => {
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState('');
    const [transactionTo, setTransactionTo] = useState('');
    const [network, setNetwork] = useState('');
    const [note, setNote] = useState('');
    const { requestWithdraw } = useContext(HomeContext);
    const base_url = window.location.hostname === 'localhost' ? 'http://127.0.0.1:8000/images/' : 'https://app.digitalmoney2024.com/images/';

    const handleSubmit = async (event) => {
        event.preventDefault();
        const amountValue = parseFloat(amount);
        if ((method.main_amount > 0 && amountValue < method.main_amount) || (method.max_amount > 0 &&amountValue > method.max_amount)) {
            toast.error(`المبلغ يجب أن يكون بين ${method.main_amount} و ${method.max_amount}`);
            return;
        }
    
        setLoading(true);
        const formData = new FormData();
        formData.append('withdraw_method_id', method.id);
        formData.append('amount', amountValue);
        formData.append('transaction_to', transactionTo);
        formData.append('network', network);
        formData.append('note', note);
    
        try {
            const res = await requestWithdraw(formData);
            setLoading(false);
    
            if (res.status) {
                toast.success(res.message);
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            setLoading(false);
            // تحقق إذا كان الرد من الخادم يتضمن رسالة خطأ واضحة
            const errorMessage = error.response?.data?.message || "حدث خطأ، الرجاء المحاولة مرة أخرى";
            toast.error(errorMessage);
            console.error(error); // احتفاظ بالخطأ في الكونسول إذا كنت بحاجة لمزيد من التفاصيل
        } 
    };
    

    return (
        <div className=" w-full px-6 py-6 mx-auto  mb-20">
 

            {/* Name Method Display */}
            <div className="mb-4 p-2 border border-gray-300 rounded-lg bg-white shadow-lg transition duration-200 hover:shadow-xl">
                <img src={base_url + method.image} width={'50'} />
                <div className="text-gray-900 text-xl font-semibold">{method.name}</div>
            </div>

            {/* Display Message and Notes if provided */}
            {method.message && (
                <div className="mb-4 p-4 bg-blue-100 rounded-lg text-blue-700 dark:bg-blue-900 dark:text-blue-300">
                   {method.message}
                </div>
            )}
            {method.note && (
                <div className="mb-4 p-4 bg-yellow-100 rounded-lg text-yellow-700 dark:bg-yellow-900 dark:text-yellow-300">
                    {method.note}
                </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-gray-700 dark:text-gray-200 font-medium mb-1">المبلغ:</label>
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                        placeholder="Enter amount"
                    />
                    <small className="text-gray-500 mt-1">
                        أقل ملغ مسموح: {method.main_amount} | أكبر مبلغ: {method.max_amount}
                    </small>
                </div>

                <div>
                    <label className="block text-gray-700 dark:text-gray-200 font-medium mb-1">
                        عنوان السحب:
                        </label>
                    <input
                        type="text"
                        value={transactionTo}
                        onChange={(e) => setTransactionTo(e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                        placeholder="Enter recipient address"
                    />
                </div>

                <div>
                    <label className="block text-gray-700 dark:text-gray-200 font-medium mb-1">
                        اختر الشبكة:
                        </label>
                    <select
                        value={network}
                        onChange={(e) => setNetwork(e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                    >
                         <option value="trc-20">TRC-20</option>
                     </select>
                </div>

                <div>
                    <label className="block text-gray-700 dark:text-gray-200 font-medium mb-1">
                        ملاحظات السحب (اختياري):
                        </label>
                    <textarea
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                        placeholder="اكتب هنا ملاحظاتك"
                    />
                </div>

                <div className="mt-4">
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        endIcon={<BsSendFill />}
                        loadingPosition="end"
                        variant="contained"
                        color="warning"
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white rounded-lg px-4 py-2"
                    >
                        حفظ
                    </LoadingButton>
                </div>
            </form>
        </div>
    );
};

export default WithdrawCrypto;
