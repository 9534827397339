import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { HomeContext } from '../../contexts/HomeContext';
import { formatDistance } from 'date-fns';
import { ar } from 'date-fns/locale'
function Notifications() {
  const { myHome, markAllNotificationsAsRead, markNotificationAsRead } = useContext(HomeContext);
  useEffect(() => {
    // console.log(myHome);
  }, [myHome]);

  const handleMarkAsRead = (notificationId) => {
    markNotificationAsRead(notificationId);
  };
  return (
    <>

      {myHome?.notifications?.map((noty, index) => (
        <li
        className={`relative mb-2 ${noty.read_at ? "bg-white dark:bg-slate-850" : "bg-blue-100 dark:bg-yellow-300"}`}
        key={noty.id}
        onClick={() => {
          if (!noty.read_at) {
            handleMarkAsRead(noty.id);
          }
        }}
      >
        <a className={`ease py-1.2 clear-both block w-full whitespace-nowrap rounded-lg bg-transparent px-4 duration-300 hover:bg-gray-200 dark:hover:bg-gray-800 hover:text-slate-700 lg:transition-colors ${noty.read_at ? "bg-white dark:bg-slate-850" : "bg-blue-100 dark:bg-yellow-300"}`}
          href="#">
          <div className="flex py-1">
            <div className="flex flex-col justify-center">
              <h6 className="mb-1 text-sm font-normal leading-normal dark:text-white">
                <span className="font-semibold" style={{ textWrapMode: 'wrap' }}>{noty.data.msg}</span>{noty.data.amount ? noty.data.amount + '$' : ''}
              </h6>
              <p className="mb-0 ml-auto text-xs leading-tight text-slate-400" style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <i className="ml-1 fa fa-clock" aria-hidden="true"></i>
                {formatDistance(
                  new Date(noty.created_at),
                  new Date(),
                  { locale: ar }
                )}
              </p>
            </div>
          </div>
        </a>
      </li>
      
      ))}
    </>

  );
}

export default Notifications;