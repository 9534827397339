import React, { useContext, useEffect } from "react";
import { Details } from "../../sections/profile/details";
import { Password } from "../../sections/profile/password";
import { HomeContext } from "../../contexts/HomeContext";
import toast from "react-hot-toast";

function Profile() {
  const { getProfile } = useContext(HomeContext);
  useEffect(() => {
    getProfile()
      .then(response => { })
      .catch(error => {
        if (error.response?.data) {
          toast.error("An error occurred retrieving data: " + error.response?.data.message);
          console.log(error.response.data.message);
        } else {
          toast.error("An unknown error has occurred");
          console.log(error);
        }
      });
  }, []);

  return (
    <div className="w-full px-6 py-6 mx-auto  mb-20">

      <div className="relative flex p-6 flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-800 dark:shadow-dark-xl rounded-2xl bg-clip-border">
        <div className="flex flex-col items-center space-y-4 text-center text-gray-800">
          <img
            className="w-32 h-32 rounded-full border-4 border-gray-300 shadow-md"
            src="/path/to/profile-picture.jpg" // استبدل بمسار الصورة الشخصية
            alt="Profile"
          />
          <h1 className="text-3xl font-semibold text-gray-900">حسابي</h1>
          <p className="text-lg text-gray-600">مرحبًا بك في صفحة البروفايل الخاصة بك!</p>
        </div>

        <main className="py-8 px-6 space-y-6">
          {/* بطاقة المعلومات الشخصية */}
          <div className="bg-gray-50 dark:bg-slate-700 p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">معلومات الحساب</h2>
            <div className="mt-4">
              <Details />
            </div>
          </div>

          {/* بطاقة تغيير كلمة المرور */}
          <div className="bg-gray-50 dark:bg-slate-700 p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">تغيير كلمة المرور</h2>
            <div className="mt-4">
              <Password />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Profile;
