import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { HomeContext, useHomeContext } from "../../contexts/HomeContext";

export const Details = () => {
  const { updateProfile } = useContext(HomeContext);
  const { dataUser } = useHomeContext();
  const [values, setValues] = useState({
    name: "",
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (dataUser) {
      setValues((prevState) => ({
        ...prevState,
        name: dataUser.user.name ?? "",
      }));
    }
  }, [dataUser]);

  const handleChange = useCallback((event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const res = await updateProfile(values);
    setLoading(false);
     if (res.status) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <div className="card">
        <div className="card-header"> 
          <div className="card-subtitle">المعلومات التي يمكنها تعديلها</div>
        </div>
        <div className="card-content">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block mb-1 text-black">اسمك</label>
              <input
                type="text"
                className="input"
                name="name"
                onChange={handleChange}
                value={values.name}
                required
              />
            </div>
          </div>
        </div>
        <div className="card-actions justify-end">
          <button
            type="submit"
            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? "يحفظ..." : "حفظ"}
          </button>
        </div>
      </div>
    </form>
  );
};