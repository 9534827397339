import React, { useContext, useEffect, useRef, useState } from 'react';
import { HomeContext } from '../../contexts/HomeContext';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import LoadingCircle from '../../components/LoadingCircle';
import MethodOnline from './MethodOnline';
import MethodOffline from './MethodOffline';


function AddRequestDeposit(props) {
    const { getMethodById } = useContext(HomeContext);

    const [method, setMethod] = useState(true);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    useEffect(() => {
        getMethod();
    }, [id]);

    const getMethod = () => {
        getMethodById(id)
            .then((response) => {
                setLoading(false);
                if (response == null) {
                    window.location.href = "/error/404";
                }
                setMethod(response);
             })
            .catch((error) => {
                if (error.response?.data) {
                    toast.error(
                        "An error occurred retrieving data" + error.response?.data?.message
                    );
                    console.log(error.response.data.message);
                } else {
                    toast.error("An unknown error has occurred");
                    console.log(error);
                }

            });
    }



    if (loading || method === null) {
        return <LoadingCircle />;
    }

    return (
        <div className="w-full px-6 py-6 mx-auto  mb-20">
            <div className="flex flex-wrap -mx-3 h-screen">
                <div className="flex-none w-full max-w-full px-3">
                    <div className="relative flex p-6 flex-col min-w-0 mb-6 bg-white border-0 shadow-xl rounded-2xl">



                        {method.type === "1" ? (
                            <MethodOffline method={method} />
                        ) : method.type === "2" ? (
                            <MethodOffline method={method} />
                        ) : method.type === "3" ? (
                            <MethodOnline method={method} />
                        ) : (
                            <MethodOffline method={method} />
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
}


export default AddRequestDeposit;